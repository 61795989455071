<template>
  <div>
    <div>
      <b-card bg-variant="light" text-variant="black" header="Shrnutí objednávky" class="text-center">
        
        <div class="w-100 text-center">
            <br><br><br>
            <h2>
                ComGate - platební formulář
            </h2>
            <br><br>
            <p>
                Pouze pro simulaci platební brány Comgate.
                <br>
                ...
            </p>
            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                @click="simulatePaymentPaid"
            >
                Simulace zaplacení platby a návrat na eshop, stav platby = PAID
            </b-button>
            <br>
            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                OLDhref="https://lovecak-test.wifcom.cz/payment/cancelled?refId=sim_CG_objednavka_id&transId=sim_CG_platba_id"
                @click="simulatePaymentCancel"
            >
                Simulace zrušení platby a návrat na eshop, stav platby = CANCELLED
            </b-button>
            <br>
            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                OLDhref="https://lovecak-test.wifcom.cz/payment/pending?refId=sim_CG_objednavka_id&transId=sim_CG_platba_id"
                @click="simulatePaymentPending"
            >
                Simulace stavu, kdy výsledek platby není znám a návrat na eshop, stav platby = PENDING
            </b-button>
            <br><br>
            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                OLDhref="https://lovecak-test.wifcom.cz/payment/pending?refId=sim_CG_objednavka_id&transId=sim_CG_platba_id"
                @click="changePaymentStateToCancelled"
            >
                Změna stavu platby ze stavu PENDING na CANCELLED
            </b-button>
            <br>
            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                OLDhref="https://lovecak-test.wifcom.cz/payment/pending?refId=sim_CG_objednavka_id&transId=sim_CG_platba_id"
                @click="changePaymentStateToPaid"
            >
                Změna stavu platby ze stavu PENDING na PAID
            </b-button>
            <br><br>
            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                :to="{ name: 'default-view', params: { }}"
            >
                {{ $t("Zpět") }}
            </b-button>
            <br><br><br>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import { /*state,*/ mutations } from '@/store/index.js'
import { createNotice } from '@/lib/gms/error.js'

export default {
    components: { },

    computed: { },

    data( ) {
        return { }
    },

    mounted( ) {
        let parameters = this.$route.query
        console.log("Parameters", parameters)
    },

    methods: {
        changePaymentStateToCancelled( ) {
            mutations.ComGateSendResultPaymentCancelled( )
                .then((/*response*/) => {
                    createNotice('Stav platby byl změněn na "CANCELLED".')
                })
                .catch(errors => {
                    mutations.processOrderDataErrors(errors)
                })
        },

        changePaymentStateToPaid( ) {
            mutations.ComGateSendResultPaymentPaid( )
                .then((/*response*/) => {
                    createNotice('Stav platby byl změněn na "PAID".')
                })
                .catch(errors => {
                    mutations.processOrderDataErrors(errors)
                })
        },

        simulatePaymentCancel( ) {
            mutations.ComGateSendResultPaymentCancelled( )
                .then(response => {
                    createNotice("Vyčkejte, budete přesměrováni zpět na eshop.")
                    
                    setTimeout(function( ) {
                        //location.replace(response.redirect)
                        window.open(response.redirect, "_blank")
                    }, 1000)
                })
                .catch(errors => {
                    mutations.processOrderDataErrors(errors)
                })
        },

        simulatePaymentPaid( ) {
            mutations.ComGateSendResultPaymentPaid( )
                .then(response => {
                    createNotice("Vyčkejte, budete přesměrováni zpět na eshop.")
                    
                    setTimeout(function( ) {
                        //location.replace(response.redirect)
                        window.open(response.redirect, "_blank")
                    }, 1000)
                })
                .catch(errors => {
                    mutations.processOrderDataErrors(errors)
                })
        },

        simulatePaymentPending( ) {
            mutations.ComGateSendResultPaymentPending( )
                .then(response => {
                    createNotice("Vyčkejte, budete přesměrováni zpět na eshop.")
                    
                    setTimeout(function( ) {
                        //location.replace(response.redirect)
                        window.open(response.redirect, "_blank")
                    }, 1000)
                })
                .catch(errors => {
                    mutations.processOrderDataErrors(errors)
                })
        }
    }
}
</script>

<style scoped>
</style>